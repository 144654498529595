 ;void function(){

    //css @depend _dialog
    //js  @depend jQuery artDialog

    /** 
    $('.J-login').click(function(){
        loginDialog.open();
        loginDialog.successCallback = function(data){
            console.log('login success'+ data);
        }
    });
    */
    
    document.domain = 'crov.com';

    var url = '//login.crov.com/asyncLogin';

    var userIdentityDialog = function(text){
        artDialog.alert(text, "Notice", {
            text: "Confirm",
            fn: function(){
                window.location.reload();
            }
        }, {
            type: "tip",close:function () {
                window.location.reload();
            }
        }
        );
        $('.userIdentityDialog-close').on('click', function (){
            window.location.reload();
        })
    }
    var loginDialog = {
        open: function(w){
            var _this = this;
            w = w ? w:390;
            var openDialog = function () {
                _this.dialog = new artDialog({
                    title:'Log in to Crov',
                    content:'<iframe id="ifr" src="'+ url +'" frameborder="0" width="'+ Number(w-20) +'" height="200"></iframe>',
                    width: w,
                    fixed: true,
                    lock: true,
                    resize: false,
                    padding: '10px'
                });
            };
            if(window.topLoginInfo){
                topLoginInfo.getInfo(function(data){
                    if(data.userName){
                        window.location.reload();
                    }else{
                        openDialog()
                    }
                });
            }else {
                openDialog();
            }

        },
        close: function(){
            this.dialog && this.dialog.close();
            topLoginInfo.request && topLoginInfo.request();
        },
        resize: function(h){
            $(this.dialog.content()).find('iframe').height(h)
        },
        success: function(data){
            this.close();
            this.successCallback(data);
        },
        openWithUserTips: function(w){
            var reload = function(){
                window.location.reload();
            }
            var win = window;
            var w = win.innerWidth < 768 ? 320:390;
            this.open(w);
            this.successCallback = function (data) {
                if (data.userIdentity === '0') {//供应商
                    userIdentityDialog('Wholesale Pricing is only available for verified business buyers.');
                } else if (data.userIdentity === '1') {//个人买家
                    reload();
                    // userIdentityDialog('Bulk pricing is only available to business buyers.<br>I am a personal buyer, <a href="javascript:void(0)" class="userIdentityDialog-close">continue browsing</a>.<br>I am a business buyer, <a href="//login.crov.com/become-business-buyer/" target="_blank">get my business account</a>.');
                } else if (data.userIdentity === '2') {//未认证企业买家
                    artDialog.confirm('Wholesale Pricing is only available for verified business account. Please check and complete your business information for quick verification.', "Notice", {
                        text: "Complete Now",
                        fn: function(){
                            window.location.href = '//buyer.crov.com/account/edit';
                            return false;
                        }
                    },{
                        text: "Do it Later",
                        fn: function(){
                            reload();
                        }
                    }, {
                        type: "tip",close:function () {
                            //按钮点击后，后触发关闭函数
                            setTimeout(function(){
                                reload();
                            },1000)
                        }
                    }
                    );
                } else {
                    reload();
                }

            }
        }
    }

    window.loginDialog = loginDialog;


 }.call(this);
 